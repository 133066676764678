import React from 'react';
import { Link, useStaticQuery, graphql } from 'gatsby';
import Img from 'gatsby-image';

import About from '../../components/about';
import Layout from '../../components/layout';
import SEO from '../../components/seo';

const IndexPage = () => {
  const data = useStaticQuery(graphql`
    query {
      aboutImage: file(relativePath: { eq: "atelier.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 1200) {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `);

  return (
    <Layout locale="pt-BR">
      <SEO
        lang="pt-BR"
        title="Sobre"
        description="Em séries recentes, Katharina vem conseguindo lograr questões
              relevantes do contemporâneo, por meio de abordagens e discussões
              que revelam uma obra mais amadurecida."
      />
      <div className="page">
        <div className="about-section">
          <About full locale="pt-BR" />
        </div>
        <div className="publications">
          <h3>Publicações</h3>
          <ul>
            <Link to="/pt-BR/publicacoes/da-domesticidade-cotidiana">
              Da domesticidade cotidiana
            </Link>
          </ul>
          <div className="page-image">
            <Img fluid={data.aboutImage.childImageSharp.fluid} />
          </div>
        </div>
      </div>
    </Layout>
  );
};

export default IndexPage;
