import React from 'react';
import PropTypes from 'prop-types';

const About = ({ full, locale = 'en' }) => (
  <div className="about" id="about">
    <h2>Katharina Welper</h2>
    {locale === 'pt-BR' ? (
      <div>
        <p>
          A obra da artista carioca Katharina Welper está ligada a um fazer que
          beira o obsessivo e que trata a memória como um lócus importante no
          desenvolvimento de sua poética. Outro dado decisivo na produção é o
          aspecto multifacetado e híbrido, já que ela trabalha com desenvoltura
          por variados suportes, como a pintura, o desenho, a colagem, a
          performance, as artes têxteis e o tridimensional. E, se pudermos
          eleger um elemento comum nesse trânsito por entre os meios, talvez a
          linha se sobressaia, fazendo com que ela se transmute das mais
          variadas maneiras e oscile entre o acúmulo e a concisão, a
          desconstrução e a criação, a quebra e a continuidade, por exemplo.
        </p>

        {full && (
          <>
            <p>
              Em séries recentes, Katharina vem conseguindo lograr questões
              relevantes do contemporâneo, por meio de abordagens e discussões
              que revelam uma obra mais amadurecida. Em novos bordados, a
              artista trabalha a partir de um ‘espólio têxtil’ de uma enfermeira
              alemã. Há poucos indícios dessa origem, como as letras EB grafadas
              como uma identificação de lençois. Nessa superfície-chassi,
              Katharina decalca um padrão copiado de antigos motivos decorativos
              originários da artesania do Leste Europeu e, num labor manual
              árduo, espalha essa linha azul a produzir um extenso desenho. A
              própria superfície do tecido também gera um resultado movediço, já
              que os pequenos pedaços vão sendo reunidos e terminam por compor
              um amálgama entre as cores branca e azul (em diferentes
              tonalidades), e porções mais lisas ou estriadas do material.
            </p>

            <p>
              Essas superfícies de histórias marcadas também se espalham nas
              pinturas de distintas séries. Os fundos móveis podem ser
              periódicos do Japão, por exemplo, nos quais a diagramação, a
              tipografia e a própria especificidade do alfabeto japonês se
              tornam importante fundamento da composição, onde por cima
              Katharina produz uma pintura de grande apuro, com um realismo
              enfatizado. E essa ‘paisagem-base’ pode mudar. Há as listas
              telefônicas, tecidos familiares à beira do descarte e variados
              materiais que aludem ao universo doméstico e mais intimista.
              Assim, a artista vai borrando limites do fazer pictórico, gráfico
              e tridimensional, conferindo mobilidade a cada recorte.
            </p>

            <p>
              Além dessa memória que Katharina trata como um legado importante,
              como um traço a não ser apagado, existe um interesse de construção
              na sua obra. Por isso, o ateliê e o cotidiano – hoje em
              Petrópolis, cidade serrana do Rio de Janeiro, e anteriomente
              transcorridos na capital carioca - viram referências relevantes em
              seu fazer. Cenas e lugares próprios por vezes protagonizam as
              pinturas, em vez de registros extraídos da web, de jornais e
              revistas, e se assentam como elementos-chave em suas composições.
              A artista também utiliza um jogo de mostrar/esconder, às vezes em
              trabalhos mais preenchidos, outras vezes em que o vazio e a
              imaginação do observador terminam por dar contornos finais à
              proposição. Tais propostas também evoluem de forma mais
              experimental, como na performance subaquática em que Katharina
              borda sobre essas superfícies já citadas e embaralha ainda mais os
              atributos de visibilidade e de experiência.
            </p>

            <p>
              Outro aspecto interessante na elaboração da produção visual de
              Katharina é a persistência. Isso fica muito claro, por exemplo,
              nas pinturas de Linhas Moiras, uma de suas individuais no Rio, em
              que a linha era o alicerce de cada quadro. Transformava-se,
              adquiria outros status, mas fazia com que sua característica una
              permanecesse, em um resultado plástico que mesclava pintura,
              desenho e colagem. “Desenho e pintura são dois órgãos do mesmo
              corpo. Vivem em cooperação para fazer viver. E um se suporta no
              outro. (…) Precisão e acúmulo, casamento de risco e condensação”
              1, escreve o artista Paulo Pasta, numa síntese que bem cabe sobre
              as qualidades da obra de Katharina Welper.
            </p>

            <p>
              <small>
                1. DERDYK, Edith (org.). Disegno. Desenho. Desígnio. São Paulo,
                Senac São Paulo, 2007, p. 87
              </small>
            </p>
            <p className="author">Mario Gioia, junho de 2016</p>
          </>
        )}
      </div>
    ) : (
      <div>
        <p>
          The work of Rio de Janeiro artist Katharina Welper is linked to a
          craft which is almost obsessive and which sees memory as a defining
          locus for the development of its poetry. Another decisive factor in
          her production is its multifaceted and hybrid nature, as she fluidly
          moves among various genres such as painting, drawing, collage,
          performance and textile art and 3-D work. And, if we can select a
          common element in this movement across genres, this thread may be
          highlighted, as it transmutes in multiple forms between abundance and
          concision, deconstruction and creation, break and continuity, for
          example.
        </p>

        {full && (
          <>
            <p>
              In her more recent series, Katharina has been able to touch
              relevant contemporary issues through approaches and discussions
              that reveal a more mature collection. In her new embroidery work,
              the artist departs from the textile spoils of a German nurse. Few
              are the signs of such an origin, such as the letters EB written as
              a bedding monogram. Over this chassis-like surface, Katharina
              decals a pattern copied from old decorative motifs originating
              from Easter European crafts and, with arduous handwork, spreads a
              blue thread to produce an extensive design. The surface of the
              material itself generates a moving pattern, as tiny pieces are put
              together making up an amalgam of whites and blues (in different
              hues), and rougher or smoother parts of the material.
            </p>

            <p>
              These historically based surfaces also spread to her paintings
              from different series. The moving backdrops may be periodic
              Japanese imagery, for instance, in which the diagraming and
              typography and the specificity of the Japanese alphabet become the
              crucial foundation on which Katharina produces very accurate
              paintwork with emphatic realism. There are phone books, old
              familial pieces of cloth and various other materials alluding to a
              more intimate and domestic world. Thus, the artist blurs the
              limits of pictorial, graphic and 3-D crafts, adding movement to
              each segment.
            </p>

            <p>
              In addition to the memory that Katharina sees as a relevant
              legacy, as a draft to be kept rather than erased, her work has an
              interest in construction. For this reason, her studio and her
              daily life – currently in Petrópolis, a small town in the
              mountains in Rio de Janeiro, and previously in the city of Rio
              itself – become relevant references in her craft. Her own scenes
              and locations are, at times, the subject of her paintings, in
              place of images from the web or from papers and magazines, playing
              a key role in her compositions. The artist also uses a play of
              hide and seek, at times creating completed works while at other
              times allowing the emptiness and the observer’s imagination to
              provide the final contours of her propositions. Her ideas also
              evolve in a more experimental fashion, such as in the underwater
              performance in which Katharina embroiders over multiple surfaces
              and thus mixes even more the attributes of visibility and
              experience.
            </p>

            <p>
              Another interesting aspect of the elaboration of Katharina’s
              visual production is her persistence. This trait becomes very
              clear, for instance, in the paintings of Linha Moira, one of her
              individual exhibitions in Rio de Janeiro, in which threads become
              the basis for each painting. They are transformed, reach a new
              status, but retain their unique characteristics, resulting in a
              plastic effect that mixes painting, drawing and collage. “Drawing
              and painting are two organs belonging to the same body. They act
              cooperatively to create life. And one is dependent on the other
              (...) Precision and accumulation, the melding of risk and
              condensation”1, writes artist Paulo Pasta, in a very appropriate
              synthesis of the nature of Katharina Welper’s work.
            </p>

            <p className="author">Mario Gioia, June 2016</p>
          </>
        )}
      </div>
    )}
    <div className="menu">
      <div className="menu__items">
        <a href="mailto:kathawelper@gmail.com">E-mail</a>
        <a
          href="https://www.instagram.com/katharinawelper"
          target="_blank"
          rel="noopener noreferrer"
        >
          Instagram
        </a>
      </div>
    </div>
  </div>
);

About.propTypes = {
  locale: PropTypes.string
};

export default About;
